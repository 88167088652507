.ql-editor{
    height: 301px;
    border: 1px solid #C8C8C8;
    border-radius: 20px;
    font-family: Poppins;
    font-size: 16px;
    padding: 23px;
}

.ql-container.ql-snow{
    border: none;
}

.ql-toolbar.ql-snow{
    display: none;
}